<template>
  <div class="movements" ref="movements">
    <div v-if="isLoadingPage">
      <Loader class="no-bg" />
    </div>
    <div v-else class="container-fluid w-auto m-4">
      <div class="row d-flex justify-content-between align-items-center mb-4">
        <div class="col">
          <h4><i class="fas fa-angle-left pr-2" style="cursor: pointer;" @click="$router.go(-1)"></i> Gestione movimenti</h4>
        </div>
        <div class="col d-flex justify-content-end">
          <Filters />
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-2 d-flex flex-row">
          <b-form-group class="w-100">
            <label for="date-picker">Inizio</label>
            <b-form-input
              type="date"
              id="date-picker"
              v-model="startDate"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2 d-flex flex-row">
          <b-form-group class="w-100">
            <label for="date-picker">Fine</label>
            <b-form-input
              type="date"
              id="date-picker"
              v-model="endDate"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-auto"></div>

        <div class="col-5 d-flex flex-column">
          <BankSelect ref="bankSelect" :banks="banks" :selectedItem="selectedBank" @update="updateBank"/>
        </div>
      </div>

      <div class="items-found">
        <div class="number-box">
          {{ totalItems}}
        </div>
        {{ totalItems === 1 ? 'movimento trovato' : 'movimenti trovati' }}
      </div>

      <div class="table-wrapper my-4">
        <b-table
          id="table"
          ref="table"
          :items="movements"
          :fields="fields"
          no-local-sorting
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          hover
          show-empty
        >
          <template #cell(bank)="row">
            <div class="d-flex align-items-center">
              <img v-if="getLogo(row.item.bankId)" :src="getLogo(row.item.bankId)" class="bank-logo md mr-2">
              <div :class="`text-bank-variant-${row.item.bankColour ? row.item.bankColour : 0}`" class="font-weight-600">
                {{ row.item.bank.alias }}
              </div>
            </div>
          </template>
          <template #cell(date)="row">
            {{ toFormattedDate(row.item.date) }}
          </template>
          <template #cell(type)="row">
            {{ row.item.type | movement | capitalizeFirstLetter }}
          </template>
          <template #cell(amount)="row">
            <CellContent
              :value="parseFloat(row.item.amount)"
              class="d-flex justify-content-end m-0 p-0 border-0"
            />
          </template>
          <template #cell(actions)="row">
            <b-dropdown
              size="sm"
              id="dropdown-1"
              right
              variant="link"
              toggle-class="text-decoration-none"
            >
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="
                  togglePopup('movementEditor');
                  selectedRow = row.item;
                "
                >Modifica</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  togglePopup('movementDelete');
                  selectedRow = row.item;
                "
                class="red"
                >Elimina</b-dropdown-item
              >
            </b-dropdown>
          </template>
          <template #empty>
            <div class="d-flex justify-content-center align-items-center py-2">
              Nessun risultato trovato
            </div>
          </template>
        </b-table>
      </div>

      <div class="row d-flex justify-content-between">
        <div class="col">
          <b-form-group
            label="Elementi per pagina"
            label-for="perPageOptions"
            class="d-flex align-items-center mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageOptions"
              class="form-control ml-3"
              :options="perPageOptions"
            />
          </b-form-group>
        </div>
        <div class="col">
          <b-pagination
            v-model="currentPage"
            aria-controls="table"
            :per-page="perPage2"
            :total-rows="totalItems"
          ></b-pagination>
        </div>
      </div>
    </div>

    <!-- popup edit -->
    <Popup ref="movementEditor" class="md">
      <template #fullContent>
        <EditMovement :obj="selectedRow" :banks="banks" @save="onEdit" />
      </template>
    </Popup>

    <Popup ref="movementDelete" class="md">
      <template #title> Sei sicuro di voler eliminare? </template>
      <template #text>
        Il movimento <b>{{ selectedRow.type | movement}}</b> di
        <b>{{ toCurrency(selectedRow.amount) }} €</b> verrà eliminato
      </template>
      <template #button>
        <custom-button
          :isLoading="loading"
          label="Elimina"
          class="w-100 mt-4"
          @click.prevent.native="onDelete"
        />
      </template>
    </Popup>

    <Snackbar :text="snackbarText" ref="snackbar" />
  </div>
</template>

<script>
import formatMovementTypeFilter from '@/filters/formatMovementType';
import capitalizeFirstLetter from '@/filters/capitalizeFirstLetter';
import { toCurrency } from '@/helpers/formValidation.js';

import {
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BTable,
} from 'bootstrap-vue';

import bankLogoDefault from '@/assets/images/bank-logo.svg';
import cashLogoDefault from '@/assets/images/cash-logo.svg';

import BankSelect from '@/views/components/BankSelect.vue';
import Button from '@/views/components/Button.vue';
import CellContent from '@/views/components/Table/CellContent.vue';
import EditMovement from '@/views/components/Forms/Movements/EditMovement.vue';
import Filters from '@/views/components/Filters.vue';
import Loader from '@/views/components/Layout/Loading.vue';
import Popup from '@/views/components/Popup.vue';
import Snackbar from '@/views/components/Snackbar.vue';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    'custom-button': Button,
    BankSelect,
    CellContent,
    EditMovement,
    Filters,
    Loader,
    Popup,
    Snackbar,
  },
  name: 'MovementsManager',
  filters: {
    movement: formatMovementTypeFilter,
    capitalizeFirstLetter: capitalizeFirstLetter,
  },
  data () {
    return {
      banks: [],
      companyId: this.$oauth2.me.companies[0].id || null,
      bankLogoDefault: bankLogoDefault,
      cashLogoDefault: cashLogoDefault,
      fields: [
        { key: 'bank', label: 'Banca', sortable: true },
        {
          key: 'date',
          label: 'Scadenza',
          class: 'text-nowrap',
          sortable: true,
        },
        { key: 'type', label: 'Tipo', sortable: false },
        { key: 'description', label: 'Descrizione', sortable: false },
        { key: 'counterpart', label: 'Controparte', sortable: false },
        { key: 'amount', label: 'Importo', sortable: true },
        { key: 'actions', label: '', sortable: false },
      ],
      movements: [],

      showPopup: true,
      snackbarText: '',
      loading: false,
      isLoadingPage: true,

      rowColour: null,
      textColour: null,

      startDate: this.$route.query.startDate || null,
      endDate: this.$route.query.endDate || null,

      // Table
      isBusy: false,
      sortDesc: Boolean(this.$route.query.sortDesc) || true,
      sortBy: this.$route.query.sortBy || 'date',
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.perPage || 10,
      perPage2: null,
      totalItems: null,
      perPageOptions: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],

      selectedBank: null,
      selectedRow: null,
    };
  },
  computed: {
    bankId: function () {
      return this.$route.query.bankId;
    },
    watchedSorting () {
      return this.sortBy + this.sortDesc;
    },
  },
  watch: {
    $route: function () {
      if (!this.isLoadingPage) {
        this.getMovements();
      }
    },
    perPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            perPage: this.perPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    currentPage: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            page: this.currentPage,
          }),
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.scrollToTop();
        });
    },
    selectedBank: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            bankId: this.selectedBank.id,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        })
        .finally(() => {
          this.updateColours();
        });
    },
    startDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            startDate: this.startDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    endDate: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            endDate: this.endDate,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
    watchedSorting: function () {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, {
            sortDesc: this.sortDesc,
            sortBy: this.sortBy,
          }),
        })
        .catch((e) => {
          // this.$log.error(e);
        });
    },
  },
  methods: {
    toCurrency (value) {
      return toCurrency(value);
    },
    toItType (type) {
      if (typeof this.itType[type] !== 'undefined') {
        return this.itType[type];
      }
      return type;
    },
    getCompanyInfo () {
      this.$api
        .getCompany(this.companyId)
        .then((res) => {
          this.banks = [{ alias: 'Tutte' }, ...res.data.banks];
          this.selectedBank = this.searchInBanks(this.bankId);
        })
        .catch((e) => {
          this.$log.error(e);
        });
    },
    getMovements () {
      this.isBusy = true;
      const filters = this.$route.query;
      this.$api
        .fetchPromisesDetails(this.companyId, filters)
        .then((res) => {
          this.totalItems = res.data.metadata['total-items'];
          this.perPage2 = res.data.metadata['per-page'];
          this.movements = res.data.promises || [];
        })
        .catch((e) => {
          this.$log.error(e);
        })
        .finally(() => {
          this.isBusy = false;
          this.updateColours();
          this.isLoadingPage = false;
        });
    },
    getLogo (bankId) {
      const bank = this.banks.find(bank => bank.id === bankId);
      return (bank && bank.logo) ? bank.logo : bankLogoDefault;
    },
    onDelete () {
      this.loading = true;
      this.$api
        .deletePromise(this.selectedRow.id)
        .then(() => {
          this.togglePopup('movementDelete');
          this.snackbarText = 'Movimento eliminato correttamente';
          this.$refs.snackbar.openSnackbar();
          this.getMovements();
        })
        .catch((e) => {
          this.$log.debug(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onEdit () {
      this.getMovements();
      this.togglePopup('movementEditor');
      this.snackbarText = 'Modifiche salvate';
      this.$refs.snackbar.openSnackbar();
    },
    scrollToTop () {
      setTimeout(() => {
        this.$refs.movements.scrollTo({
          top: 250,
          left: 0,
          behavior: 'smooth',
        });
      }, 500);
    },
    searchInBanks () {
      return this.banks.find((bank) => bank.id === this.bankId);
    },
    toFormattedDate (date) {
      const x = new Date(date);
      return (
        ('0' + x.getDate()).slice(-2) +
        '/' +
        ('0' + (x.getMonth() + 1)).slice(-2) +
        '/' +
        x.getFullYear().toString()
      );
    },
    togglePopup (ref) {
      this.$refs[ref].togglePopup();
    },
    updateBank (bank) {
      this.selectedBank = bank;
    },
    updateColours () {
      this.rowColour = this.selectedBank?.colour
        ? `bank-variant-${this.selectedBank.colour}-light`
        : 'primary-light';
      this.textColour = this.selectedBank?.colour
        ? `text-bank-variant-${this.selectedBank.colour}`
        : 'text-primary';
    },
    replaceImg (e) {
      e.target.src = bankLogoDefault;
    },
  },
  mounted () {
    this.$root.$on('updateMovements', () => {
      this.getCompanyInfo();
      this.getMovements();
    });
    this.getCompanyInfo();
    this.getMovements();
  },
};
</script>

<style lang="scss">
.no-bg {
  background: transparent !important;
}
.movements {
  background: $primary-lighter;

  // selected bank box
  .box {
    border: none;
    // background: $primary-light;
    border-radius: 10px;
    padding: 15px;
    transition: all 0.5s;
  }

  .items-found {
    cursor: default;
    display: flex;
    text-transform: lowercase;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    width: fit-content;
    border-radius: 8px;

    background: none;
    color: $primary;

    .number-box {
      padding: 3px;
      background: $primary-light;
      height: 20px;
      min-width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      border-radius: 5px;
    }
  }
  // row settings
  .dropdown {
    button {
      // no caret
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      border-radius: 10px;
      padding: 0;
      overflow: hidden;
      box-shadow: $box-shadow;
      &:focus {
        outline: none;
      }
      .red > * {
        color: $danger !important;
      }
      .dropdown-item {
        color: $primary;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 14px;
        &:focus {
          outline: none;
        }
        &:active {
          background: $primary-light;
        }
      }
    }
  }

  .bank-logo {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .table-wrapper {
    border-radius: 10px;
    box-shadow: $box-shadow;
    // overflow: hidden;
    height: min-content;
  }

  table {
    background: white;
    text-overflow: ellipsis;
    font-size: 16px;
    margin: 0;
    border-radius: 10px;
    th {
      border: none;
      text-transform: uppercase;
      font-size: 14px;
      color: rgba($primary, 0.75);
      font-weight: 600;
      &:nth-last-child(2) {
        text-align: right;
      }
    }
    thead {
      border: none !important;
      background: $fin-white;
      border-radius: 10px;
      th {
        padding: 23px 15px;
      }
    }
  }
}
</style>
